import { Box, Button, Flex, NumberInput, Select, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { Aircraft, AircraftPerfDetails, AircraftPerfDetailsSchema, Location } from "@soar/shared/types"
import { useEffect, useMemo } from "react"

export function AircraftPerfForm({
  onSubmit,
  onCancelClick,
  submitButtonText,
  value,
  requireLocation = false,
  locations = [],
  disabled = false,
}: {
  disabled?: boolean
  requireLocation?: boolean
  locations?: Location[]
  value?: Aircraft | AircraftPerfDetails
  onSubmit: (values: AircraftPerfDetails) => void
  onCancelClick: () => void
  submitButtonText?: string
}) {
  const form = useForm<AircraftPerfDetails>({
    validate: zodResolver(AircraftPerfDetailsSchema),
  })

  const handleCancelClick = () => {
    form.reset()
    onCancelClick()
  }

  useEffect(() => {
    if (value != null) {
      form.setValues({
        airspeed: value.airspeed ?? 0,
        tailNumber: value.tailNumber,
        fuelBurn: value.fuelBurn ?? 0,
        maxWeight: value.maxWeight ?? 0,
        maxWindsThreshold: value.maxWindsThreshold ?? 0,
        locationId: value.locationId,
      })
    }
  }, [value])

  useEffect(() => {
    if (form.values.locationId == null && requireLocation && locations.length > 0) {
      form.setFieldValue("locationId", locations[0].id)
    }
  }, [locations, form, requireLocation])

  const locationOptions = useMemo(() => {
    return locations.map((location) => ({
      value: location.id,
      label: location.name,
    }))
  }, [locations])

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (values) => {
        if (!disabled) {
          onSubmit(values)
        }
      })}
    >
      <Stack spacing="sm">
        <TextInput label="Tail number" placeholder="N1234GK" {...form.getInputProps("tailNumber")} disabled={disabled} />
        <NumberInput
          label="True airspeed"
          placeholder="120"
          hideControls
          precision={1}
          inputMode="decimal"
          disabled={disabled}
          {...form.getInputProps("airspeed")}
        />
        <NumberInput
          label="Fuel burn per hour"
          placeholder="8"
          hideControls
          precision={1}
          inputMode="decimal"
          disabled={disabled}
          {...form.getInputProps("fuelBurn")}
        />
        <NumberInput
          label="Max payload (lbs)"
          placeholder="500"
          hideControls
          precision={1}
          inputMode="decimal"
          disabled={disabled}
          {...form.getInputProps("maxWeight")}
        />
        <NumberInput
          label="Max winds (kts)"
          placeholder="Max winds"
          hideControls
          precision={1}
          inputMode="decimal"
          disabled={disabled}
          {...form.getInputProps("maxWindsThreshold")}
        />
        {requireLocation && (
          <Select
            label="Location"
            required
            defaultValue={locationOptions[0].value}
            data={locationOptions}
            disabled={disabled}
            {...form.getInputProps("locationId")}
          />
        )}
      </Stack>
      {!disabled && (
        <Flex justify="flex-end" gap="md" mt="xl">
          <Button variant="default" onClick={handleCancelClick}>
            {" "}
            Cancel{" "}
          </Button>
          <Button type="submit" variant="gradient" gradient={{ from: "#136DFB", to: "#0351CC", deg: 180 }}>
            {" "}
            {submitButtonText ?? "Add aircraft"}{" "}
          </Button>
        </Flex>
      )}
    </Box>
  )
}
