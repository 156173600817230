import {
  Affix,
  AppShell,
  Avatar,
  Box,
  Button,
  CloseButton,
  Flex,
  MediaQuery,
  Menu,
  NavLink,
  Navbar,
  Portal,
  ScrollArea,
  Space,
  Text,
  UnstyledButton,
  UnstyledButtonProps,
  createStyles,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { Organization } from "@prisma/client"
import {
  BUTTON_GRADIENT_COLORS,
  Header,
  HeaderActionButton,
  MaterialSymbol,
  PAGE_BACKGROUND_COLOR,
  headerButtonPortalId,
} from "@soar/frontend/components"
import {
  SelectionContext,
  featureFlagsAtom,
  organizationAtom,
  organizationsForUserAtom,
  organizationsSelectableForUserAtom,
  organizationsWithRolesForUserAtom,
  profileAtomWithStorage,
  useSelections,
  useUser,
} from "@soar/frontend/contexts"
import { PermissionNameEnum, Profile, RegisteredUser, isOrganizationProfile } from "@soar/shared/types"
import { getOrganizationShortName, getUserName } from "@soar/shared/utils"
import { IconCalendar, IconChevronDown, IconSettings, IconUsers } from "@tabler/icons-react"
import { IconPlus } from "@tabler/icons-react"
import { useAtom, useAtomValue } from "jotai"
import {
  BackupProfileAvatar,
  OrganizationSwitcher,
  OrganizationSwitcherMobile,
  getProfileInfo,
} from "libs/frontend/components/src/lib/organizations/switcher"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { CSSProperties, ReactNode, createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import soarLogo from "../public/glyph-soar-white.svg"
import { IntercomLink, IntercomLinkInHeader } from "./IntercomLink"
import { SafetyControlModal } from "./safety/safetyControlModal"
import { SafetyReportModal } from "./safety/safetyReportModal"

const showOrgsOnUser = false

function stub() {
  console.warn("this is a stub fn")
}

const defaultStyle: CSSProperties = {
  backgroundColor: PAGE_BACKGROUND_COLOR,
}

interface ILayoutContext {
  setStyle: (style: CSSProperties) => void
  resetStyle: () => void
  hideHeader: boolean
  setHideHeader: (hidden: boolean) => void
}
export const LayoutContext = createContext<ILayoutContext>({
  resetStyle: stub,
  setStyle: stub,
  hideHeader: false,
  setHideHeader: stub,
})

const useSideMenuStyles = createStyles((theme) => ({
  label: {
    fontWeight: 700,
    color: theme.white,
  },
  body: {
    marginLeft: "8px",
  },
  navLinkIcon: {
    marginRight: "0.3rem",
  },
  navLinkRoot: {
    color: theme.white,
    marginBottom: "0.4rem",
    ["&[data-active]"]: {
      backgroundColor: "transparent",
      backdropFilter: "brightness(90%)",
    },
    ["&:hover"]: {
      backgroundColor: "transparent",
      backdropFilter: "brightness(115%)",
    },
  },
  textDecorationNone: {
    textDecoration: "none",
  },
  navBarBackground: {
    backgroundColor: "#126bf7",
    // background: "linear-gradient(142deg, #136DFB 0%, #0351CC 100%)",
  },
  navBarSubBackground: {
    background: "linear-gradient(180deg, #0351CC 0%, #136DFB 8%, #136DFB 40%, #0351CC 100%)",
  },
  closeButton: {
    color: "#FFF",
    ["&:hover"]: {
      backgroundColor: "transparent",
      backdropFilter: "brightness(115%)",
    },
  },
  bottomNav: {
    background: "linear-gradient(180deg, #136DFB 0%, #0351CC 100%)",
  },
  equalFlex: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
  },
  bottomNavLinkWrapper: {
    paddingBottom: "calc(calc(env(safe-area-inset-bottom) / 2 ) + 5px)",
  },
  bottomNavLink: {
    color: theme.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
    height: "60px",
  },
  bottomNavActive: {
    backdropFilter: "brightness(90%)",
    fontWeight: 700,
  },
}))

function SideMenu({ expanded, setExpanded }: { expanded: boolean; setExpanded: (value: boolean) => void }) {
  const { classes, cx } = useSideMenuStyles()
  const router = useRouter()

  const navLinkClasses = {
    label: classes.label,
    body: classes.body,
    root: classes.navLinkRoot,
    icon: classes.navLinkIcon,
  }

  const { onOrganizationSelect, onUserSelect, selectedProfile } = useContext(SelectionContext)

  const profileInfo = getProfileInfo(selectedProfile)
  const organizationsForUser = useAtomValue(organizationsSelectableForUserAtom)
  const organization = useAtomValue(organizationAtom)
  const { user, hasPermission } = useUser()
  const featureFlags = useAtomValue(featureFlagsAtom)

  const onProfileSelect = (profile: Profile) => {
    if (profile.type === "organization") {
      onOrganizationSelect(profile.organization)
    } else {
      onUserSelect()
    }
  }

  const currentPath = router.pathname

  const closeNav = () => setExpanded(false)

  const menuType = selectedProfile?.type === "organization" ? "organization" : "user"
  const onScheduleClick = (organization: Organization) => {
    onOrganizationSelect(organization)
    closeNav()
  }

  const showCreate = user?.canCreateOrg ?? false
  const disabled = (organizationsForUser?.length ?? 0) === 0 && showCreate === false

  return (
    <Navbar
      hidden={!expanded}
      hiddenBreakpoint="md"
      style={{ backgroundColor: "rgba(0,0,0, 0.7)", borderRight: "none" }}
      width={{
        md: 300,
      }}
      onClick={closeNav}
    >
      <Flex
        maw={{ base: 275, md: 300 }}
        h="100%"
        direction="column"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Navbar.Section className={classes.navBarBackground} px="xl" pt="xl" pb="xs">
          <Flex direction="column" align="center">
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <CloseButton style={{ alignSelf: "flex-start" }} c="#FFF" size="md" onClick={closeNav} className={classes.closeButton} />
            </MediaQuery>
            <Space h="xs" />
            <Image alt="soar glyph" src={soarLogo} width={50} height={50} />
            <Space h="xs" />
            <img height={30} src="/soar-name-logo-white.svg" alt="soar text logo" />
            <Space h="xl" />
            <OrganizationSwitcher
              user={user}
              selectedProfile={selectedProfile ?? undefined}
              onProfileSelect={onProfileSelect}
              showCreate={user?.canCreateOrg ?? false}
              onOrgSwitch={(organization) => {
                router.push(`/organization/${organization.id}/dashboard`)
                closeNav()
              }}
              onUserSwitch={() => {
                router.push("/home")
                closeNav()
              }}
            >
              <NavLink
                sx={(theme) => ({
                  borderRadius: `${theme.radius.md}px`,
                  boxShadow: `${theme.shadows.md}px`,
                  ["&:hover"]: {
                    background: "transparent",
                    backdropFilter: "brightness(115%)",
                  },
                })}
                classNames={navLinkClasses}
                icon={
                  <Avatar src={profileInfo.avatar ?? null} color="blue" size={28} p={2} radius="xl">
                    <BackupProfileAvatar user={user} selectedProfile={selectedProfile ?? undefined} />
                  </Avatar>
                }
                label={profileInfo.label}
                rightSection={disabled ? undefined : <IconChevronDown size={20} color="white" />}
              />
            </OrganizationSwitcher>
          </Flex>
        </Navbar.Section>
        <Navbar.Section className={classes.navBarSubBackground} p="xl" component={ScrollArea} grow>
          {menuType === "organization" && (
            <>
              {hasPermission(PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD) && (
                <Link href={`/organization/${organization?.id}/dashboard`} className={classes.textDecorationNone} onClick={closeNav}>
                  <NavLink
                    label={"Home"}
                    icon={<img src="/home.svg" alt="home icon" width="24" height="24" />}
                    active={currentPath === "/organization/[id]/dashboard"}
                    classNames={navLinkClasses}
                    variant="filled"
                  />
                </Link>
              )}
              <Link href={`/organization/${organization?.id}/data`} className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label={"Data"}
                  icon={<span className="material-symbols-outlined"> monitoring </span>}
                  active={currentPath === "/organization/[id]/data"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>

              <Link href="/manage/aircraft" className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label={"Aircraft"}
                  icon={<img src="/plane-outlined.svg" alt="plane-outlined" width="24" height="24" />}
                  active={currentPath === "/manage/aircraft"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>

              <Link href="/manage/users" className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label={"Users"}
                  icon={<IconUsers />}
                  active={currentPath === "/manage/users"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>

              {hasPermission(PermissionNameEnum.enum.EDIT_ORGANIZATION_SETTINGS) && (
                <Link href="/manage" className={classes.textDecorationNone} onClick={closeNav}>
                  <NavLink
                    label={"Settings"}
                    icon={<IconSettings />}
                    active={currentPath === "/manage"}
                    classNames={navLinkClasses}
                    variant="filled"
                  />
                </Link>
              )}
            </>
          )}
          {menuType === "user" && (
            <>
              <Link href="/home" className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label="Home"
                  icon={<img src="/home.svg" alt="home icon" width="24" height="24" />}
                  active={currentPath === "/home"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>
              <Link href="/safety" className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label={<Text> Safety AI</Text>}
                  icon={<img src="/safety.svg" alt="safety icon" width="24" height="24" />}
                  active={currentPath === "/safety"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>
              {/*
              <Link href="/aircraft" className={classes.textDecorationNone} onClick={closeNav}>
                <NavLink
                  label={"Aircraft"}
                  icon={<img src="/plane-outlined.svg" alt="plane-outlined" width="24" height="24" />}
                  active={currentPath === "/aircraft"}
                  classNames={navLinkClasses}
                  variant="filled"
                />
              </Link>
              */}
              {organizationsForUser != null && organizationsForUser.length > 0 && showOrgsOnUser && (
                <Link href="/reservations" className={classes.textDecorationNone} onClick={closeNav}>
                  <NavLink
                    label={"Reservations"}
                    icon={
                      <img
                        src="/format_list_bulleted_FILL0_wght400_GRAD0_opsz24.svg"
                        style={{ filter: "invert(1)" }}
                        alt="reservations icon"
                      />
                    }
                    active={currentPath === "/reservations"}
                    classNames={navLinkClasses}
                    variant="filled"
                  />
                </Link>
              )}

              {organizationsForUser != null && organizationsForUser.length > 0 && showOrgsOnUser && (
                <>
                  <Space h="xl" />
                  <Space h="sm" />
                  <Text tt="uppercase" fw={700} size="sm" color="gray.4" ml="sm" mt="xl" mb="lg">
                    Organizations
                  </Text>
                  {organizationsForUser.map((organization) => {
                    const onClick = () => onScheduleClick(organization as Organization)
                    return (
                      <Link href="/schedule" className={classes.textDecorationNone} onClick={onClick} key={organization.id}>
                        <NavLink
                          label={organization.name}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-circle-filled"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              role="img"
                            >
                              <title>circle icon</title>
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path
                                d="M7 3.34a10 10 0 1 1 -4.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 4.995 -8.336z"
                                strokeWidth="0"
                                fill="currentColor"
                              />
                            </svg>
                          }
                          classNames={navLinkClasses}
                        />
                      </Link>
                    )
                  })}
                </>
              )}
            </>
          )}
        </Navbar.Section>
      </Flex>
    </Navbar>
  )
}
function useBuildId() {
  const [buildId, setBuildId] = useState<string>()
  const [buildFound, setBuildFound] = useState<boolean>(true)
  useEffect(() => {
    const scriptElement = document.querySelector("script#__NEXT_DATA__")
    const scriptContents = JSON.parse(scriptElement?.textContent ?? "")
    console.log("Build id: ", scriptContents.buildId)
    setBuildId(scriptContents.buildId)
  }, [])

  useEffect(() => {
    async function validateBuildId() {
      console.log("checking build: ", buildId)
      let online = true
      try {
        const response = await fetch("/api/info")
        if (response.ok) {
          online = true
        } else {
          online = false
        }
      } catch {
        console.error("unknown error while checking build")
        online = false
      }

      if (online) {
        try {
          const response = await fetch(`/_next/static/${buildId}/_buildManifest.js`, {
            method: "HEAD",
            cache: "no-store",
          })

          if (response.ok) {
            setBuildFound(true)
          } else {
            setBuildFound(false)
          }
        } catch {
          console.error("unknown error while checking build")
          setBuildFound(false)
        }
      }
    }

    let intervalId: ReturnType<typeof setInterval>

    if (buildId != null) {
      validateBuildId()
      intervalId = setInterval(validateBuildId, 10 * 60 * 1000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [buildId])

  return {
    buildId,
    buildFound,
  }
}

function MobileNavLink<T extends string | ReactNode, U = T>({
  href,
  alt,
  icon,
  iconActive,
  label,
  activePath,
}: {
  href: string
  alt: string
  label: string
  icon: T
  iconActive: U
  activePath?: string
}) {
  const router = useRouter()
  const active = router.pathname === (activePath ?? href)
  // console.log(href, ": ", active)

  return (
    <Link href={href} passHref legacyBehavior>
      <MobileNavButton alt={alt} icon={icon} iconActive={iconActive} label={label} isLink active={active} href={href} />
    </Link>
  )
}

const MobileNavButton = forwardRef(
  <T extends string | ReactNode, U = T>(
    {
      alt,
      icon,
      iconActive,
      label,
      active,
      onClick,
      href,
      isLink,
    }: {
      alt: string
      label: string
      icon: T
      iconActive: U
      active: boolean
      isLink: boolean
      href?: string
      onClick?: () => void
    },
    ref,
  ) => {
    const { classes, cx } = useSideMenuStyles()

    return (
      <UnstyledButton
        onClick={onClick}
        component={isLink ? "a" : "button"}
        href={href}
        ref={ref}
        className={cx(classes.equalFlex, classes.bottomNavLinkWrapper, { [classes.bottomNavActive]: active })}
      >
        <Box className={classes.bottomNavLink}>
          {typeof icon === "string" && typeof iconActive === "string" ? (
            <img src={active ? iconActive : icon} alt={alt} width="20" height="20" />
          ) : active ? (
            (iconActive as ReactNode)
          ) : (
            icon
          )}
          <Text fz="10px" lh={1}>
            {label}
          </Text>
        </Box>
      </UnstyledButton>
    )
  },
)

function MobileNavBar({
  user,
}: {
  user?: RegisteredUser
}) {
  const router = useRouter()
  const { classes, cx } = useSideMenuStyles()
  const { selectedProfile } = useSelections()
  const organizationsForUser = useAtomValue(organizationsSelectableForUserAtom)
  const organization = useAtomValue(organizationAtom)
  const profileInfo = getProfileInfo(selectedProfile)
  const { hasPermission, hasPermissionForOrganization } = useUser()
  const featureFlags = useAtomValue(featureFlagsAtom)
  const [drawerOpened, drawerHandlers] = useDisclosure(false)

  const menuType = selectedProfile?.type === "organization" ? "organization" : "user"

  return (
    <>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Box h="80px" />
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Affix w="100%">
          <Flex className={classes.bottomNav} id="mobile-nav">
            {menuType === "user" ? (
              <>
                <MobileNavLink
                  href={"/home"}
                  label="Home"
                  icon="/icons/home-outlined.svg"
                  iconActive="/icons/home-filled-old.svg"
                  alt="home icon"
                />
                <MobileNavLink
                  href={"/safety"}
                  label="Safety AI"
                  icon="/icons/shield-outlined.svg"
                  iconActive="/icons/shield-filled.svg"
                  alt="shield icon"
                />
              </>
            ) : (
              <>
                {hasPermission(PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD) && (
                  <MobileNavLink
                    href={`/organization/${organization?.id}/dashboard`}
                    activePath="/organization/[id]/dashboard"
                    label="Home"
                    icon="/icons/home-outlined.svg"
                    iconActive="/icons/home-filled-old.svg"
                    alt="home icon"
                  />
                )}
                <MobileNavLink
                  href={`/organization/${organization?.id}/data`}
                  activePath="/organization/[id]/data"
                  label="Data"
                  icon={
                    <Flex align="center" h="20px">
                      <MaterialSymbol name="analytics" fz="26px" />
                    </Flex>
                  }
                  iconActive={
                    <Flex align="center" h="20px">
                      <MaterialSymbol name="analytics" fz="26px" fill={true} />
                    </Flex>
                  }
                  alt="data icon"
                />
                <MobileNavLink
                  href={"/manage/aircraft"}
                  label="Aircraft"
                  icon="/icons/airplane-outlined.svg"
                  iconActive="/icons/airplane-filled.svg"
                  alt="aircraft icon"
                />
                <MobileNavLink
                  href={"/manage/users"}
                  label="Users"
                  icon={
                    <Flex align="center" h="20px">
                      <MaterialSymbol name="group" fz="28px" />
                    </Flex>
                  }
                  iconActive={
                    <Flex align="center" h="20px">
                      <MaterialSymbol name="group" fz="28px" fill={true} />
                    </Flex>
                  }
                  alt="users"
                />
              </>
            )}
            {(organizationsForUser?.length ?? 0) > 0 && (
              <OrganizationSwitcherMobile
                opened={drawerOpened}
                onClose={drawerHandlers.close}
                onOrgSwitch={(organization) => {
                  if (hasPermissionForOrganization(organization.id, PermissionNameEnum.enum.VIEW_ORGANIZATION_DASHBOARD)) {
                    router.push(`/organization/${organization.id}/dashboard`)
                  } else {
                    router.push(`/organization/${organization.id}/data`)
                  }
                }}
                onUserSwitch={() => {
                  router.push("/home")
                }}
              >
                <MobileNavButton
                  label={isOrganizationProfile(selectedProfile) ? getOrganizationShortName(selectedProfile.organization) : "Me"}
                  icon={<Avatar src={profileInfo.avatar ?? null} size={20} radius="xl" />}
                  isLink={false}
                  active={false}
                  iconActive=""
                  alt=""
                  onClick={drawerHandlers.open}
                />
              </OrganizationSwitcherMobile>
            )}
          </Flex>
        </Affix>
      </MediaQuery>
    </>
  )
}

function HeaderActionButtonWrapper() {
  const organizations = useAtomValue(organizationsForUserAtom)
  const { hasPermissionForOrganization } = useUser()
  const [safetyReportModalState, safetyReportModalHandlers] = useDisclosure(false)
  const [safetyControlModalState, safetyControlModalHandlers] = useDisclosure(false)
  const [menuState, menuHandlers] = useDisclosure(false)

  const canCreateSafetyControls = useMemo(() => {
    return (organizations ?? []).some((org) => {
      return hasPermissionForOrganization(org.id, PermissionNameEnum.enum.CREATE_SAFETY_CONTROLS)
    })
  }, [hasPermissionForOrganization, organizations])

  const onClick = () => {
    if (canCreateSafetyControls) {
      menuHandlers.open()
    } else {
      safetyReportModalHandlers.open()
    }
  }

  if ((organizations ?? []).length === 0) {
    return <Box />
  }
  return (
    <>
      <Menu
        opened={menuState}
        onClose={menuHandlers.close}
        disabled={!canCreateSafetyControls}
        shadow="lg"
        radius="md"
        offset={{
          mainAxis: 15,
          crossAxis: -40,
        }}
        styles={(theme) => ({
          dropdown: {
            marginRight: theme.spacing.sm,
            marginLeft: theme.spacing.sm,
          },
        })}
      >
        <Menu.Target>
          <HeaderActionButton onClick={onClick} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<MaterialSymbol name="prompt_suggestion" />} onClick={safetyReportModalHandlers.open}>
            Submit safety report
          </Menu.Item>
          <Menu.Item icon={<MaterialSymbol name="add_circle" />} onClick={safetyControlModalHandlers.open}>
            Create risk control
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <SafetyReportModal opened={safetyReportModalState} onClose={safetyReportModalHandlers.close} />
      <SafetyControlModal opened={safetyControlModalState} onClose={safetyControlModalHandlers.close} />
    </>
  )
}

function Layout({ children }) {
  const { isAuthenticated, isSignedInToAuthService, user, logout } = useUser()
  const router = useRouter()

  const [style, setStyle] = useState<CSSProperties>(defaultStyle)
  const [navbarExpanded, setNavbarExpanded] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)

  const toggleNav = () => {
    setNavbarExpanded((navbarExpanded) => !navbarExpanded)
  }

  const resetStyle = useCallback(() => {
    setStyle(defaultStyle)
  }, [setStyle])

  const { buildId, buildFound } = useBuildId()

  useEffect(() => {
    if (!buildFound) {
      notifications.show({
        color: "blue",
        title: "New version available",
        autoClose: false,
        message: (
          <Flex py="xs" align="center">
            <Text>Refresh to get the latest version.</Text>
            <Button variant="light" ml="xs" compact onClick={router.reload}>
              Refresh
            </Button>
          </Flex>
        ),
      })
    }
  }, [buildFound])

  return (
    <LayoutContext.Provider value={{ resetStyle, setStyle, hideHeader, setHideHeader }}>
      <AppShell
        style={style}
        layout="alt"
        navbarOffsetBreakpoint="md"
        header={
          hideHeader ? undefined : (
            <Header
              isAuthenticated={isAuthenticated && isSignedInToAuthService}
              avatarUrl={user?.avatarUrl ?? undefined}
              onLogout={logout}
              toggleNavbarExpanded={toggleNav}
            >
              {isAuthenticated && isSignedInToAuthService && <IntercomLink />}
              {isAuthenticated && isSignedInToAuthService && <HeaderActionButtonWrapper />}
            </Header>
          )
        }
        navbar={isAuthenticated && isSignedInToAuthService ? <SideMenu expanded={navbarExpanded} setExpanded={setNavbarExpanded} /> : <></>}
        padding={0}
      >
        <Box id="content-root">{children}</Box>
        {isAuthenticated && isSignedInToAuthService && <MobileNavBar user={user} />}
      </AppShell>
    </LayoutContext.Provider>
  )
}

export default Layout
