import { UserRole } from "@soar/shared/types"

export const roleConfig: Record<
  UserRole,
  {
    label: string
    description: string
  }
> = {
  [UserRole.enum.PILOT]: {
    label: "Pilot",
    description:
      "Line pilots who can run and submit FRATs and safety reports. Focused on their flying duties with no access to operational controls.",
  },
  [UserRole.enum.MECHANIC]: {
    label: "Mechanic",
    description:
      "Can submit safety reports but does not have access to FRATs or operational functions. Focused solely on maintenance-related safety reporting.",
  },
  [UserRole.enum.STAFF_PILOT]: {
    label: "Ops manager",
    description:
      "Pilots with some management privileges. Can submit reports, run FRATs, and step into the operational view when needed to approve high-risk flights. Ideal for pilots who assist with safety decisions beyond their flying role.",
  },
  [UserRole.enum.ADMIN]: {
    label: "Administrator",
    description:
      "Full access to both the operational and pilot experience. Can switch between managing safety operations and flying duties, run and submit FRATs, submit safety reports, analyze safety data, and approve high-risk flights. Typically used by safety managers or senior pilots handling safety oversight.",
  },
  [UserRole.enum.SUPERADMIN]: {
    label: "Super administrator",
    description: "This role owns the account",
  },
}
